export const socialsData = {
    github: 'https://github.com/XdithyX',
    facebook: 'https://www.facebook.com/',
    linkedIn: 'https://www.linkedin.com/in/adithya-ajith-474545203/',
    instagram: 'https://www.instagram.com/',
    Leetcode: 'https://leetcode.com/4adhi007/',
    twitter: 'https://twitter.com/XdithyX',
   /* reddit: 'https://www.reddit.com/user/',
    blogger: 'https://www.blogger.com/',
    medium: 'https://medium.com/@',
    stackOverflow: 'https://stackoverflow.com/users/',
    gitlab: 'https://gitlab.com/',
    youtube: 'https://youtube.com/'*/
}