import resume from '../assets/pdf/resume.pdf'
import img from '../assets/img/hello.jpg'

export const headerData = {
    name: 'Adithya Ajith',
    title: "Web Developer",
    desciption:`"Talent without work is nothing"  \n   Cristiano Ronaldo .............................................................................................   `,
    image: img,
    resumePdf: resume
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////